import type { ComputedRef, Ref } from 'vue';
import {
  ref,
  computed,
  toRef,
  watch,
} from 'vue';
import { useRoute, useRouter } from 'vue-router';

import { AlertIconName, IconName } from '@leon-hub/icons';
import type { FormSchema } from '@leon-hub/form-utils';
import {
  PromoActionType,
  RemoteApiErrorExceptionCode,
  SportBonusState,
} from '@leon-hub/api-sdk';
import { normalizeError } from '@leon-hub/errors';
import { TargetParamEnter } from '@leon-hub/yandex-metrika';
import { assert, isOptionalString, isString } from '@leon-hub/guards';
import type { NavigationItem } from '@leon-hub/navigation-config';
import { ApiError, GqlApiPromotionNotFoundError, isGeneralApiError } from '@leon-hub/api';
import { RouteName } from '@leon-hub/routing-config-names';

import { getImageOrIcon } from 'web/src/modules/icons';
import { getLeaderBoardList, getLeaderBoardOptions } from 'web/src/modules/promotions/store/utils';
import { ModalSelector } from 'web/src/modules/core/enums';
import type { FormExternalErrors, FormOutput, FormUiSchema } from 'web/src/components/Form/types';
import { useIsLoggedIn } from 'web/src/modules/auth/composables';
import type {
  PromotionMergedDetailsDocument,
  PromotionScrollableElement,
} from 'web/src/modules/promotions/types';
import { useLeaderBoardStore, useCashbackStore, usePromotionsStore } from 'web/src/modules/promotions/store';
import { useNavigationItemsStore, useRouterStore, useSiteConfigStore } from 'web/src/modules/core/store';
import { ButtonKind } from 'web/src/components/Button/VButton/enums';
import type { ConfirmDialog, ShowDialogOptions } from 'web/src/modules/dialogs/types';
import { ModalWidth } from 'web/src/components/Modal/enums';
import { DialogAction, DialogComponent, PresetName } from 'web/src/modules/dialogs/enums';
import { JumbotronIconKind } from 'web/src/components/Jumbotron';
import { useCustomerDataStore } from 'web/src/modules/customer/store';
import { useAnalytics } from 'web/src/modules/analytics/composables';
import { useUserStore } from 'web/src/modules/user/store';
import { useErrorsConverter } from 'web/src/modules/errors/composables';
import { useI18n } from 'web/src/modules/i18n/composables';
import { useDialogs } from 'web/src/modules/dialogs/composables';
import { usePromotionDetailsCore } from 'web/src/modules/promotions/submodules/details/composables';
import useIdentificationNeededModal from 'web/src/modules/dialogs/composables/useIdentificationNeededModal';
import type { CashBackRounds } from 'web/src/modules/promotions/store/types';
import type { PromotionTermsProps } from 'web/src/modules/promotions/components/PromotionTerms/types';
import type { LeaderBoardProps } from 'web/src/modules/promotions/components/LeaderBoard/types';
import type { PromotionButtonProps } from 'web/src/modules/promotions/components/PromotionButton/types';
import type BaseDialog from 'web/src/modules/dialogs/types/BaseDialog';
import DateTime from 'web/src/utils/DateTime';
import type { VImageProps } from 'web/src/components/Image/VImage/types';
import { ObjectFitOption } from 'web/src/components/Image/VImage/enums';
import { usePinCodeStore } from 'web/src/modules/pin-code/store';

export interface PromotionDetailsRoutePageComposable {
  board: Ref<PromotionScrollableElement | undefined>;
  buttonProperties: Ref<PromotionButtonProps | null>;
  currentPromoLeaderboardDate: Ref<string>;
  cashbackAmount: Ref<number>;
  contentLoaded: Ref<boolean>;
  counterTimestamp: Ref<number>;
  currentPromotion: Ref<PromotionMergedDetailsDocument>;
  customErrors: Ref<FormExternalErrors>;
  hasCounter: Ref<boolean>;
  imageProps: Ref<VImageProps>;
  isArchived: Ref<boolean>;
  isBeforeStart: Ref<boolean>;
  isCashbackInfoModal: Ref<boolean>;
  isCustomerParticipatingCashback: Ref<boolean>;
  isDoingActionButton: Ref<boolean>;
  isLeaderBoard: Ref<boolean>;
  isNicknameFlowFinal: Ref<boolean>;
  isNicknameModal: Ref<boolean>;
  isPromotionsLeaderboardArchiveEnabled: Ref<boolean>;
  isReady: Ref<boolean>;
  isShowPromoSocialLinks: Ref<boolean>;
  isShowTermsData: Ref<boolean>;
  leaderBoardProperties: Ref<LeaderBoardProps>;
  modalCashbackInfoProps: Ref<Partial<ConfirmDialog>>;
  modalProps: Ref<{ modal: Partial<ConfirmDialog> }>;
  modalSelector: ModalSelector;
  rounds: Ref<CashBackRounds | undefined>;
  schema: FormSchema;
  showBadges: Ref<boolean>;
  showCountdown: Ref<boolean>;
  showCashbackHistory: Ref<boolean>;
  spoilerRecord: Ref<Record<number, boolean>>;
  terms: Ref<PromotionScrollableElement | undefined>;
  termsData: ComputedRef<PromotionTermsProps>;
  uiSchema: Ref<FormUiSchema>;
  isLoggedIn: Ref<boolean>;
  isCurrentCashback: Ref<boolean>;
  isCashbackRefuseDialogLoading: Ref<boolean>;
  pageName: Ref<string>;

  tournamentProperties(id: number): Ref<LeaderBoardProps>;
  refuseCashback(): void;
  sendRequest(): Promise<void>;
  preDoButtonAction(): Promise<void>;
  goToLoginPage(): void;
  doButtonAction(): Promise<void>;
  scrollToLeaderBoard(): void;
  scrollToTerms(): void;
  goToCustomLink(): void;
  goToPlay(): void;
  goToIdentification(): void;
  loadMore(id?: number): Promise<void>;
  onSpoilerOpen(id: number): Promise<void>;
  onClose(): void;
  onLater(): Promise<void>;
  onInput(): void;
  onSubmit(data: FormOutput): Promise<void>;
  onButtonClick({ action }: { action: DialogAction }): Promise<void>;
  beforeMount(): void;
  beforeUnmount(): void;
  onGoBackClick(): void;
  onTermsClick(): void;
  showIdentificationNeededModal(): void;
  doDeposit(): void;
}

// eslint-disable-next-line max-len,sonarjs/cognitive-complexity
export default function usePromotionDetailsRoutePage(): PromotionDetailsRoutePageComposable {
  const cashbackStore = useCashbackStore();
  const cashbackAmountStore = toRef(cashbackStore, 'cashbackAmount');
  const cashback = toRef(cashbackStore, 'cashback');
  const isCashbackBeforeStart = toRef(cashbackStore, 'isCashbackBeforeStart');
  const cashbackCounterTimestamp = toRef(cashbackStore, 'cashbackCounterTimestamp');
  const actualRoundStartDate = toRef(cashbackStore, 'actualRoundStartDate');
  const isCurrentCashback = toRef(cashbackStore, 'isCurrentCashback');

  const { $translate } = useI18n();
  const analytics = useAnalytics();
  const route = useRoute();
  const router = useRouter();
  const { showDialog, closeDialog } = useDialogs();
  const errorsConverter = useErrorsConverter();
  const { showIdentificationNeededModal } = useIdentificationNeededModal();

  const {
    isLeaderBoardPromo,
    actionUrl,
    currentPromoLeaderboardDate,
    doButtonAction,
    scrollToLeaderBoard,
    goToCustomLink,
    loadMore,
    goToPlay,
    goToIdentification,
    promotionButton,
    isDoingActionButton,
    scrollToTerms,
    terms,
    board,
    doDeposit,
  } = usePromotionDetailsCore();

  const userStore = useUserStore();
  const scgStore = useSiteConfigStore();
  const routerStore = useRouterStore();
  const pinCodeModule = usePinCodeStore();

  const promotionsBlock = toRef(scgStore, 'promotionsBlock');
  const isPromotionsRequestNicknameEnabled = toRef(scgStore, 'isPromotionsRequestNicknameEnabled');

  const customerDataStore = useCustomerDataStore();
  const customerNickname = toRef(customerDataStore, 'nickname');

  const promotionsStore = usePromotionsStore();
  const currentPromotion = toRef(promotionsStore, 'promotion');
  const promotionsHasCounter = toRef(promotionsStore, 'hasCounter');
  const promotionsIsBeforeStart = toRef(promotionsStore, 'isBeforeStart');
  const promotionsCounterTimestamp = toRef(promotionsStore, 'counterTimestamp');
  const isBlockParticipationButton = toRef(promotionsStore, 'isBlockParticipationButton');
  const isCustomerParticipating = toRef(promotionsStore, 'isCustomerParticipating');

  const { isLoggedIn } = useIsLoggedIn();
  const promotionCategoriesNavigationItems = toRef(useNavigationItemsStore(), 'promotionCategoriesNavigationItems');

  const leaderBoardStore = useLeaderBoardStore();
  const leaderBoardListMap = toRef(leaderBoardStore, 'leaderBoardListMap');
  const leaderBoardOptionsMapRecord = toRef(leaderBoardStore, 'leaderBoardOptionsMapRecord');

  const previousRouteName = toRef(routerStore, 'previousRouteName');

  const hasLoginPinCode = computed(() => !!pinCodeModule.pinCodeRequestedAtLogin);

  const contentLoaded = ref(false);
  const spoilerRecord = ref<Record<number, boolean>>({});
  const nickname = ref('');
  const isNicknameModal = ref(false);
  const isNicknameFlowFinal = ref(false);
  const isInputButtonDisabled = ref(false);
  const customErrors = ref<FormExternalErrors>({});
  const isCashbackInfoModal = ref(false);
  const isCashbackInfoModalLoading = ref(false);
  const isCashbackRefuseDialogLoading = ref(false);
  const tournamentPropertiesCache: Record<string, Ref<LeaderBoardProps>> = {};

  const modalSelector: ModalSelector = ModalSelector.BODY;

  const schema: FormSchema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    type: 'object',
    required: ['nickname'],
    properties: {
      nickname: {
        type: 'string',
      },
    },
  };

  const isCashbackPromo = computed(() => currentPromotion.value?.promotionType === PromoActionType.EGS_CASHBACK);

  const cashbackAmount = computed(
    () => (process.env.VUE_APP_FEATURE_CASHBACK_ENABLED ? cashbackAmountStore.value : 0),
  );

  const isPromotionsLeaderboardArchiveEnabled = computed<boolean>(
    () => !!promotionsBlock.value?.isPromotionsLeaderboardArchiveEnabled,
  );

  const showBadges = computed<boolean>(
    () => !!currentPromotion.value?.isHot || !!currentPromotion.value?.isNew || !!currentPromotion.value?.isNT,
  );

  const isCashback = computed<boolean>(() => !!process.env.VUE_APP_FEATURE_CASHBACK_ENABLED
    && isCashbackPromo.value);

  const isCustomerParticipatingCashback = computed<boolean>(() => isCashback.value && isCustomerParticipating.value);

  const hasCounter = computed<boolean>(() => promotionsHasCounter.value || isCustomerParticipatingCashback.value);

  const imageProps = computed(() => ({
    isLazy: true,
    showLoader: true,
    objectFit: ObjectFitOption.COVER,
    ...(currentPromotion.value?.shortBannerImage ?? {
      src: '',
    }),
  }));

  const isBeforeStart = computed<boolean>(
    () => {
      if (!process.env.VUE_APP_FEATURE_SLOTT_STYLE_COMPONENTS_ENABLED) {
        return !!process.env.VUE_APP_FEATURE_CASHBACK_ENABLED && isCustomerParticipatingCashback.value
          ? isCashbackBeforeStart.value : promotionsIsBeforeStart.value;
      }
      return process.env.VUE_APP_FEATURE_CASHBACK_ENABLED
      && isCashbackPromo.value
        ? isCashbackBeforeStart.value : promotionsIsBeforeStart.value;
    },
  );

  const counterTimestamp = computed<number>(
    () => {
      if (!process.env.VUE_APP_FEATURE_SLOTT_STYLE_COMPONENTS_ENABLED) {
        return !!process.env.VUE_APP_FEATURE_CASHBACK_ENABLED && isCustomerParticipatingCashback.value
          ? cashbackCounterTimestamp.value : promotionsCounterTimestamp.value;
      }
      return process.env.VUE_APP_FEATURE_CASHBACK_ENABLED
      && isCashbackPromo.value
        ? cashbackCounterTimestamp.value : promotionsCounterTimestamp.value;
    },
  );

  const disabledStatus = computed<boolean>(() => isLoggedIn.value
    && !!isBlockParticipationButton.value
    && hasCounter.value && isBeforeStart.value);

  const buttonProperties = computed<PromotionButtonProps | null>(() => (!promotionButton.value ? null : {
    buttonItem: promotionButton.value,
    isDisabled: disabledStatus.value,
  }));

  const leaderBoardProperties = computed<LeaderBoardProps>(() => {
    const url = actionUrl.value ?? '';
    const list = getLeaderBoardList(url, leaderBoardListMap.value);
    const options = getLeaderBoardOptions(
      url,
      url,
      leaderBoardOptionsMapRecord.value,
    );
    return {
      list,
      endOfList: options?.nextPage === 0,
      isLoading: options?.isLoading,
      customerPosition: options?.customerPosition ?? undefined,
    };
  });

  const pageName = route.name === RouteName.PROMOTION_DETAILS
    ? $translate('WEB2_PAGE_NAME_ALL_PROMOTIONS') : $translate('WEB2_PAGE_NAME_ALL_TOURNAMENTS');

  const showCountdown = computed(() => {
    if (process.env.VUE_APP_FEATURE_SLOTT_STYLE_COMPONENTS_ENABLED && isCashbackPromo.value) {
      if (isCustomerParticipatingCashback.value) {
        return hasCounter.value && !isCurrentCashback.value;
      }
      if (!isCustomerParticipatingCashback.value && isBeforeStart.value) {
        return false;
      }
      return isCashback.value;
    }
    return hasCounter.value;
  });

  const showCashbackHistory = computed(() => (!process.env.VUE_APP_FEATURE_SLOTT_STYLE_COMPONENTS_ENABLED
    ? isCustomerParticipatingCashback.value : true));

  function tournamentProperties(id: number): Ref<LeaderBoardProps> {
    const stringId = String(id);
    if (!tournamentPropertiesCache[stringId]) {
      tournamentPropertiesCache[stringId] = computed<LeaderBoardProps>(() => {
        const list = getLeaderBoardList(stringId, leaderBoardListMap.value);
        const options = getLeaderBoardOptions(
          actionUrl.value || '',
          stringId,
          leaderBoardOptionsMapRecord.value,
        );
        return {
          list,
          endOfList: options?.nextPage === 0,
          isLoading: options?.isLoading,
          customerPosition: options?.customerPosition ?? undefined,
        };
      });
    }

    return tournamentPropertiesCache[stringId];
  }

  const isLeaderBoard = computed<boolean>(
    () => getLeaderBoardList(actionUrl.value || '', leaderBoardListMap.value).length > 0,
  );

  const isShowTermsData = computed<boolean>(() => (currentPromotion.value?.sportBonus?.state !== SportBonusState.CLOSED
    && (!!currentPromotion.value?.sportBonus?.bonusRequirements.length
      || !!currentPromotion.value?.sportBonus?.wager?.requirements.length)
    && currentPromotion.value?.promotionType === PromoActionType.SBC));

  const termsData = computed<PromotionTermsProps>(() => ({
    sportBonus: currentPromotion.value?.sportBonus ?? undefined,
    termsKey: currentPromotion.value?.termsOfActions || '',
  }));

  const isReady = computed<boolean>(() => currentPromotion.value?.actionUrl !== undefined);

  const isArchived = computed<boolean>(() => !!currentPromotion.value?.isArchived);

  const isShowPromoSocialLinks = computed<boolean>(
    () => (currentPromotion.value?.socialLinks && currentPromotion.value.socialLinks.length > 0) ?? false,
  );

  const rounds = computed<CashBackRounds | undefined>(() => {
    const cashbackRounds = cashback.value.cashback?.rounds;

    if (!!cashbackRounds && cashbackRounds?.length) {
      if (process.env.VUE_APP_FEATURE_SLOTT_STYLE_COMPONENTS_ENABLED) {
        const currentDateTime = Date.now();
        return cashbackRounds.filter((item) => {
          const roundEndDate = DateTime.toTimestamp(item.roundEndDate);
          return roundEndDate < currentDateTime;
        });
      }
      return cashbackRounds;
    }

    return undefined;
  });

  const finalModalConfirmMessageBtrParams = computed(() => ({
    nickname: nickname.value,
  }));

  const finalConfirmMessage = $translate('WEB2_NICKNAME_SAVED_MSG', finalModalConfirmMessageBtrParams);

  const modalProps = computed<{ modal: Partial<ConfirmDialog> }>(() => {
    const initialModal: Partial<ConfirmDialog> = {
      iconName: IconName.PERSON,
      width: ModalWidth.SMALL,
      isCentered: true,
      title: $translate('WEB2_CREATE_NICKNAME').value,
      confirmMessage: $translate('WEB2_NICKANAME_PROPOSAL').value,
      buttons: [
        {
          kind: ButtonKind.TRANSPARENT,
          action: DialogAction.MODAL_CLOSE,
          label: $translate('WEB2_LATER').value,
        },
      ],
    };
    const finalModal: Partial<ConfirmDialog> = {
      iconName: IconName.CHECK_OUTLINE,
      iconKind: JumbotronIconKind.SUCCESS,
      width: ModalWidth.SMALL,
      isCentered: true,
      title: $translate('WEB_NICKNAME_SAVED').value,
      confirmMessage: finalConfirmMessage.value,
      buttons: [
        {
          kind: ButtonKind.PRIMARY,
          action: DialogAction.MODAL_CLOSE,
          label: $translate('JSP_PCL_FBOT_CLOSE').value,
        },
      ],
    };
    return {
      modal: isNicknameFlowFinal.value ? finalModal : initialModal,
    };
  });

  const modalCashbackInfoProps = computed<Partial<ConfirmDialog>>(() => ({
    iconName: IconName.ATTENTION_OUTLINE,
    width: ModalWidth.SMALL,
    isCentered: true,
    title: $translate('WEB2_CASHBACK_IMPORTANT_INFO_TITLE').value,
    confirmMessage: $translate('WEB2_CASHBACK_IMPORTANT_INFO_TEXT').value,
    buttons: [
      {
        kind: ButtonKind.PRIMARY,
        action: DialogAction.CONFIRM,
        label: $translate('WEB2_CASHBACK_IMPORTANT_INFO_ACCEPT').value,
        isLoading: isCashbackInfoModalLoading.value,
      },
      {
        kind: ButtonKind.TRANSPARENT,
        action: DialogAction.MODAL_CLOSE,
        label: $translate('WEB2_CLOSE').value,
      },
    ],
  }));

  function goToLoginPage(): void {
    analytics.clickMap({ enter: TargetParamEnter.PROMO_PAGE });
    void router.push({ name: RouteName.LOGIN });
  }

  const routeActionUrl = computed<string | null>(() => {
    const { actionUrl: actionUrlParam } = route.params;
    assert(isOptionalString(actionUrlParam));

    return actionUrlParam || null;
  });

  async function getData(): Promise<void> {
    if (!routeActionUrl.value) {
      return;
    }

    await initialDataRequest();
    sendYM();

    const scrollToTermsQuery = router.getQuery('scrollToTerms');

    if (scrollToTermsQuery) {
      await router.replace({ query: {} });
      setTimeout(scrollToTerms, 0);
    }
  }

  const navigationItem = computed<NavigationItem | null>(() => {
    const { categoryId } = route.params;
    return promotionCategoriesNavigationItems.value
      .find((item) => item.id === categoryId) ?? null;
  });

  function sendYM(): void {
    if (navigationItem.value?.caption && currentPromotion.value?.name) {
      promotionsStore.promotionsSendYM({
        categoryName: navigationItem.value?.caption,
        promotionName: currentPromotion.value?.name,
      });
    }
  }

  function showErrorModal(rawError: unknown): void {
    let params: BaseDialog | null = null;

    if (rawError instanceof ApiError && String(rawError.code) === 'PROMOTION_IS_UNAVAILABLE') {
      params = {
        title: $translate('WEB2_PROMO_UNAVAILABLE_TITLE').value,
        confirmMessage: $translate('WEB2_PROMO_UNAVAILABLE_MESSAGE').value,
        image: getImageOrIcon({ alertIcon: AlertIconName.Fail }).image,
      };
    }

    if (rawError instanceof GqlApiPromotionNotFoundError) {
      if (String(rawError.code) as RemoteApiErrorExceptionCode === RemoteApiErrorExceptionCode.PROMOTION_NOT_FOUND) {
        params = {
          title: $translate('WEB2_PROMO_NOT_FOUND_TITLE').value,
          confirmMessage: $translate('WEB2_PROMO_NOT_FOUND_WRONG_URL_MESSAGE').value,
          image: getImageOrIcon({ alertIcon: AlertIconName.Fail }).image,
        };
      } else {
        void router.replace({ name: RouteName.PROMOTIONS });
        return;
      }
    }

    if (params) {
      const { subscribe, id } = showDialog({
        presetName: PresetName.CONFIRM,
        options: {
          title: params.title,
          image: params.image,
          confirmMessage: params.confirmMessage,
          width: process.env.VUE_APP_LAYOUT_DESKTOP ? ModalWidth.SMALL : ModalWidth.DEFAULT,
          buttons: [
            { label: $translate('WEB2_OTHER_PROMOS_BUTTON').value },
            { label: $translate('WEB2_CLOSE').value },
          ],
          dataTestId: 'promo-error',
        },
      });

      subscribe({
        [DialogAction.CONFIRM]: () => {
          closeDialog(id);
          void router.replace({ name: RouteName.PROMOTIONS });
        },
        [DialogAction.MODAL_CLOSE]: () => {
          closeDialog(id);
          void router.replace({ name: RouteName.PROMOTIONS });
        },
      });

      return;
    }

    throw rawError;
  }

  async function initialDataRequest(): Promise<void> {
    try {
      actionUrl.value = routeActionUrl.value ?? '';
      if (actionUrl.value) {
        assert(isString(actionUrl.value));
        leaderBoardStore.resetLeaderBoard();
        spoilerRecord.value = {};
        await Promise.all([
          handleLoadPromotionDetails(actionUrl.value),
          leaderBoardStore.getLeaderBoard({
            actionUrl: actionUrl.value,
            id: actionUrl.value,
            isUpdateAfterLogin: true,
          }),
        ]);
      }
    } catch (rawError) {
      showErrorModal(rawError);
    }
  }

  async function handleLoadPromotionDetails(actionUrlParam: string): Promise<void> {
    try {
      await promotionsStore.loadPromotionDetailsWithPolling({ actionUrl: actionUrlParam, initial: true });
      if (process.env.VUE_APP_FEATURE_CASHBACK_ENABLED && isLoggedIn.value) {
        await cashbackStore.getCashback();
      }
    } catch (rawError) {
      showErrorModal(rawError);
    } finally {
      contentLoaded.value = true;
    }
  }

  async function onSpoilerOpen(id: number): Promise<void> {
    if (spoilerRecord.value[id] !== undefined) {
      spoilerRecord.value[id] = !spoilerRecord.value[id];
    } else {
      await loadMore(id);
      spoilerRecord.value[id] = true;
    }
  }

  async function sendRequest(): Promise<void> {
    if (!!process.env.VUE_APP_FEATURE_CASHBACK_ENABLED && actualRoundStartDate.value !== '') {
      await initialDataRequest();
    }
  }

  function refuseCashback(): void {
    if (process.env.VUE_APP_FEATURE_CASHBACK_ENABLED) {
      const modalProperties: ShowDialogOptions = {
        presetName: PresetName.CONFIRM,
        options: {
          title: $translate('WEB2_BCAMPAIGN_OPT_OUT_CONFIRMATION_TITLE').value,
          confirmMessage: $translate('WEB2_BONUSES_REFUSE_CASHBACK_CONFIRM_MESSAGE').value,
          modalComponent: DialogComponent.ConfirmModal,
          width: ModalWidth.SMALL,
          image: getImageOrIcon({ alertIcon: AlertIconName.QuestionMark }).image,
          buttons: [
            {
              label: $translate('WEB2_BONUSES_REFUSE_BUTTON').value,
            },
          ],
        },
      };

      try {
        isCashbackRefuseDialogLoading.value = true;
        const { subscribe } = showDialog(modalProperties);

        subscribe({
          [DialogAction.CONFIRM]: async () => {
            await Promise.all([
              cashbackStore.refuseCashback(),
              initialDataRequest(),
            ]).finally(() => {
              isCashbackRefuseDialogLoading.value = false;
            });
          },
          [DialogAction.MODAL_CLOSE]: () => {
            isCashbackRefuseDialogLoading.value = false;
          },
        });
      } catch (rawError) {
        const error = normalizeError(rawError);

        if (isGeneralApiError(error)) {
          throw error;
        }
      }
    }
  }

  async function confirmCashbackParticipation(): Promise<void> {
    try {
      isCashbackInfoModalLoading.value = true;
      await Promise.all([
        cashbackStore.confirmCashback(),
        cashbackStore.getCashback(),
        initialDataRequest(),
      ]);
      cashbackStore.setCashbackRefused(false);
      isCashbackInfoModalLoading.value = false;
    } catch (rawError) {
      isCashbackInfoModalLoading.value = false;
      const error = normalizeError(rawError);

      if (isGeneralApiError(error)) {
        throw error;
      }
    }
  }

  async function onButtonClick({ action }: { action: DialogAction }): Promise<void> {
    if (!!process.env.VUE_APP_FEATURE_CASHBACK_ENABLED && action === DialogAction.CONFIRM) {
      await confirmCashbackParticipation();
      onClose();
    }

    if (action === DialogAction.MODAL_CLOSE) {
      onClose();
    }
  }

  const uiSchema = computed<FormUiSchema>(() => ({
    submitButton: {
      label: $translate('WEB2_CREATE_BONUS_CODE').value,
      disabled: isInputButtonDisabled.value,
    },
    fields: {
      nickname: {
        options: {
          placeholder: $translate('WEB2_NICKNAME').value,
        },
      },
    },
  }));

  function onInput(): void {
    isInputButtonDisabled.value = false;
  }

  async function onSubmit(data: FormOutput): Promise<void> {
    if (!data.errors) {
      const { nickname: nicknameForm } = data.formData;
      if (typeof nicknameForm === 'string') {
        try {
          await userStore.updateNickname({ nickname: nicknameForm });
          nickname.value = nicknameForm;
          isNicknameFlowFinal.value = true;
          await userStore.loadCustomerData();
        } catch (rawError) {
          const error = errorsConverter.convertToBaseError(rawError);
          isInputButtonDisabled.value = true;
          customErrors.value = {
            nickname: [{
              value: nicknameForm,
              message: error.message,
            }],
          };
        }
      }
    }
  }

  async function onLater(): Promise<void> {
    onClose();
    await doButtonAction();
  }

  function onClose(): void {
    isNicknameModal.value = false;
    isCashbackInfoModal.value = false;
  }

  async function preDoButtonAction(): Promise<void> {
    if (!customerNickname.value && isLeaderBoardPromo.value && isPromotionsRequestNicknameEnabled.value) {
      isNicknameModal.value = true;
    } else if (isCashbackPromo.value) {
      if (promotionsBlock.value?.isCashbackLoyaltyModalEnabled) {
        isCashbackInfoModal.value = true;
      } else {
        await confirmCashbackParticipation();
      }
    } else {
      await doButtonAction();
    }
  }

  function onGoBackClick(): void {
    const { categoryId } = currentPromotion.value;

    if (previousRouteName.value) {
      void router.back();
    } else if (route.name === RouteName.PROMOTION_DETAILS) {
      void router.push({ name: RouteName.PROMOTIONS, params: { categoryId } });
    } else {
      void router.push({ name: RouteName.TOURNAMENTS, params: { categoryId } });
    }
  }

  function onTermsClick(): void {
    if (termsData.value.termsKey) {
      void router.push({ name: RouteName.CMS_PROMO_TERMS, params: { cmsKey: termsData.value.termsKey } });
    }
  }

  function beforeMount(): void {
    void getData();
  }

  function beforeUnmount() {
    promotionsStore.setOpenedActiveUrl('');
  }

  watch(routeActionUrl, getData);

  watch(isLoggedIn, () => {
    if (process.env.VUE_APP_LAYOUT_DESKTOP && hasLoginPinCode.value) {
      void router.closeModal();
    }

    void initialDataRequest();
  });

  watch(currentPromotion, (newValue) => {
    if (newValue?.categoryId && newValue.categoryId !== route.params.categoryId) {
      const params = { name: RouteName.PROMOTION_DETAILS, params: { categoryId: newValue.categoryId } };
      if (process.env.VUE_APP_LAYOUT_DESKTOP) {
        void router.push302(params);
      } else {
        void router.replace(params);
      }
    }
  }, { immediate: true });

  return {
    isReady,
    currentPromotion,
    showBadges,
    hasCounter,
    isBeforeStart,
    counterTimestamp,
    isCustomerParticipatingCashback,
    buttonProperties,
    isArchived,
    isDoingActionButton,
    contentLoaded,
    isShowTermsData,
    termsData,
    currentPromoLeaderboardDate,
    rounds,
    imageProps,
    isLeaderBoard,
    leaderBoardProperties,
    isPromotionsLeaderboardArchiveEnabled,
    spoilerRecord,
    isShowPromoSocialLinks,
    isNicknameModal,
    modalSelector,
    modalProps,
    isNicknameFlowFinal,
    schema,
    uiSchema,
    customErrors,
    terms,
    cashbackAmount,
    isCashbackInfoModal,
    modalCashbackInfoProps,
    board,
    isLoggedIn,
    isCurrentCashback,
    pageName,
    showCountdown,
    showCashbackHistory,
    isCashbackRefuseDialogLoading,
    sendRequest,
    preDoButtonAction,
    scrollToLeaderBoard,
    doButtonAction,
    goToLoginPage,
    scrollToTerms,
    goToCustomLink,
    goToPlay,
    goToIdentification,
    refuseCashback,
    loadMore,
    onSpoilerOpen,
    tournamentProperties,
    onClose,
    onLater,
    onInput,
    onSubmit,
    onButtonClick,
    beforeMount,
    beforeUnmount,
    onGoBackClick,
    onTermsClick,
    showIdentificationNeededModal,
    doDeposit,
  };
}
