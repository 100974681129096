<script lang="ts" setup>
import type {
  Ref,
  VNode,
} from 'vue';
import {
  computed,
  ref,
  watch,
} from 'vue';

import { VEmpty } from 'web/src/components/Empty';

interface RenderlessSpoilerProps {
  opened?: boolean;
}

const props = defineProps<RenderlessSpoilerProps>();
const slots = defineSlots<{
  default?(props: {
    isOpen: Ref<boolean>;
    toggle(): void;
    open(): void;
    close(): void;
  }): VNode[];
}>();

const isOpen = ref(false);

watch(() => props.opened, (to) => {
  isOpen.value = !!to;
}, { immediate: true });

function toggle(): void {
  isOpen.value = !isOpen.value;
}

function open(): void {
  isOpen.value = true;
}

function close(): void {
  isOpen.value = false;
}

const component = computed(() => (
  slots.default
    ? slots.default({
        isOpen,
        toggle,
        open,
        close,
      })
    : VEmpty
));
</script>

<template>
  <component v-auto-id="'VRenderlessSpoiler'" :is="component" />
</template>
